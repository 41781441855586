import axios from 'axios';
import router from '../router'
import Vue from 'vue'
import {getToken,removeToken} from '@/utils/auth'
import {
  Message,
  MessageBox
} from 'element-ui';


// 创建axios实例
const service = axios.create({
  timeout: 100000, // 请求超时时间
  widthCredentials: true
});
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// request拦截器
service.interceptors.request.use(config => {
  if (getToken()!='undefined') {
    let ownTo = window.sessionStorage.getItem('ownToken');
    if(ownTo){
      // 判断上传的时候，在header里面token
      // if(config.url=='/api/pc/common/uploadFileOss'){
        config.headers['ownToken'] = getToken();
      // }
    }else{
      config.headers['Authorization'] = getToken(); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
    }
  }
  return config;
}, error => {
  Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    const res = response.data;
    if (response.status === 401 || res.status === 40101) {
      MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken()
        window.sessionStorage.removeItem('ownToken')
        window.sessionStorage.removeItem('time')
        router.replace({
          path: '/login' // 到登录页重新获取token
        })
      })
      return Promise.reject('error');
    }
    if (res.status === 40301) {
      router.replace({
        path: '/login' // 到登录页重新获取token
      })
      return Promise.reject('error');
    }
    if (response.status !== 200 && res.status !== 200) {
      console.log(response)
    } else {
      return response.data;
    }
  },
  error => {
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

export default service;